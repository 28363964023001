import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getHomepage, getRecordings, getEvents } from "../contentful";
import marked from "marked";
import RecordingsHero from "../components/RecordingsHero";
import Ben from "../assets/imgs/ben-half.jpg";
import EventsDisplay from "../components/EventsDisplay/EventsDisplay";
import Translator from "../components/i18n/Translator";

const Home = () => {
  const [content, setContent] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getHomepage().then((data) => setContent(data[0].fields));
    getRecordings().then((data) => setRecordings(data));
    getEvents().then((data) => setEvents(data));
  }, []);

  const today = new Date();
  return (
    <div>
      {content && (
        <div>
          <div className='home-header'>
            <div className='home-header__image'>
              <img src={Ben} />
            </div>
            <div className='home-header__cd'>
              <h3>
                {" "}
                <Translator path='NewRelease' />
              </h3>
              <RecordingsHero recordings={recordings} homepage />
              <Link className='cta cta--light' to='recordings'>
                <Translator path='ViewAllRecordings' />
              </Link>
            </div>
          </div>
          <div className='page-content main-section '>
            <h3>
              {" "}
              <Translator path='NextConcerts' />
            </h3>
            <EventsDisplay
              events={events
                .filter((e) => new Date(e.fields.date) >= today)
                .slice(0, 4)}
            />
            <Link className='cta cta--light' to='calendar'>
              <Translator path='ViewAllDates' />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
