import React from "react";
import "./styles.scss";
import { FaPhoneAlt, FaTimesCircle } from "react-icons/fa";

const ContactModal = ({ close }) => {
  return (
    <div className='modal-container'>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Contact</h2>
        <FaTimesCircle onClick={close} style={{ cursor: "pointer" }} />
      </div>
      <div className='mail-container'>
        <a href='mailto:benvannespen@scarlet.be'>benvannespen@scarlet.be</a>
      </div>
      <div className='phone-container'>
        <FaPhoneAlt />
        <span>+32 477 37 26 84</span>
      </div>
    </div>
  );
};

export default ContactModal;
