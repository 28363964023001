import React, { useState } from "react";
import "./globalStyles.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Backdrop from "./components/Backdrop/Backdrop";
import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Biog from "./pages/Biog";
import Recordings from "./pages/Recordings";
import Calendar from "./pages/Calendar";
import Media from "./pages/Media";
import Projects from "./pages/Projects";
import ContactModal from "./components/ContactModal";
import Press from "./pages/Press";
import Lessons from "./pages/Lessons";
import GalleryPage from "./pages/Gallery";

const App = () => {
  const [sideDrawer, setSideDrawer] = useState(false);
  const [contactModal, setContactModal] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawer(!sideDrawer);
  };

  const backdropClickHandler = () => {
    setSideDrawer(false);
  };

  const backdrop = sideDrawer ? (
    <Backdrop click={backdropClickHandler} />
  ) : null;

  return (
    <Router>
      <div style={{ height: "100%" }}>
        <Toolbar
          openContact={() => setContactModal(true)}
          drawerClickHandler={drawerToggleClickHandler}
        />
        <SideDrawer
          openContact={() => setContactModal(true)}
          show={sideDrawer}
          click={drawerToggleClickHandler}
        />
        {backdrop}
        {contactModal && <ContactModal close={() => setContactModal(false)} />}
        <main style={{ marginTop: 100 }}></main>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/biog' component={Biog} />
          <Route path='/recordings' component={Recordings} />
          <Route path='/calendar' component={Calendar} />
          <Route path='/media' component={Media} />
          <Route path='/gallery' component={GalleryPage} />
          <Route path='/press' component={Press} />
          <Route path='/teaching' component={Lessons} />
          <Route path='/projects' component={Projects} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
