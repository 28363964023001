const client = require("contentful").createClient({
  space: "cbufzw4npqnt",
  accessToken: "MN9u9zrFhv3JHNoQ5RDzrtY7CfzmvxObMPt5EWrCl_M",
});
var gt = new Date().toISOString();

const getBiog = () =>
  client
    .getEntries({ content_type: "biog" })
    .then((response) => response.items);

const getProjects = () =>
  client
    .getEntries({ content_type: "project" })
    .then((response) => response.items);

const getHomepage = () =>
  client
    .getEntries({ content_type: "homepage" })
    .then((response) => response.items);
const getRecordings = () =>
  client
    .getEntries({ content_type: "recording", order: "fields.order" })
    .then((response) => response.items);

const getEvents = () =>
  client
    .getEntries({
      content_type: "calendarEvent",
      order: "fields.date",
      // "fields.date[gte]": gt,
    })
    .then((response) => response.items);

const getYoutube = () =>
  client
    .getEntries({ content_type: "youtubeVideo", order: "fields.order" })
    .then((response) => response.items);

const getPressReviews = () =>
  client
    .getEntries({ content_type: "pressReview", order: "fields.order" })
    .then((response) => response.items);

const getTeachingPage = () =>
  client
    .getEntries({ content_type: "lessonsPage" })
    .then((response) => response.items);

const getGalleryImages = () =>
  client
    .getEntries({ content_type: "galleryImage" })
    .then((response) => response.items);

const getPressPage = () =>
  client
    .getEntries({ content_type: "pressPage" })
    .then((response) => response.items);

const getProjectsPage = () =>
  client
    .getEntries({ content_type: "projectsPage" })
    .then((response) => response.items);

const getCalendarPage = () =>
  client
    .getEntries({ content_type: "calendarPage" })
    .then((response) => response.items);

const getRecordingsPage = () =>
  client
    .getEntries({ content_type: "recordingsPage" })
    .then((response) => response.items);

export {
  getCalendarPage,
  getRecordingsPage,
  getProjectsPage,
  getPressPage,
  getTeachingPage,
  getBiog,
  getHomepage,
  getRecordings,
  getEvents,
  getYoutube,
  getProjects,
  getPressReviews,
  getGalleryImages,
};
