import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import marked from "marked";
import Translator from "../i18n/Translator";

const ProjectCard = ({ title, desc, details, image }) => {
  const [showDesc, setShowDesc] = useState(false);
  const [detailsHeight, setDetailsHeight] = useState(0);
  const detailsRef = useRef();

  useEffect(() => {
    setDetailsHeight(showDesc ? detailsRef.current.scrolHeight : 0);
  }, [showDesc]);

  return (
    <div className='main-container programme-card'>
      {image && (
        <div className='programme-card__image'>
          <img alt='' src={image} />
        </div>
      )}
      <div className='programme-card-text'>
        <h1 className='programme-card-text__title'>{title}</h1>
        <h4 className='programme-card-text__subtitle '>{desc}</h4>
        <button
          className='button'
          onClick={() => setShowDesc(!showDesc ? true : false)}
        >
          <Translator path={!showDesc ? "ReadMore" : "ReadLess"} />
        </button>
        {details && showDesc && (
          <div
            style={{ height: detailsHeight }}
            ref={detailsRef}
            className='details-container'
          >
            <section
              className='body-text programme-card-text__details'
              dangerouslySetInnerHTML={{
                __html: marked(details),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
