import React from "react";
import Gallery from "../components/Gallery";

const GalleryPage = () => {
  return (
    <div className='page-content'>
      {" "}
      <Gallery />
    </div>
  );
};

export default GalleryPage;
