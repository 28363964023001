import React, { useState, useEffect } from "react";
import "./styles.scss";
import { SRLWrapper } from "simple-react-lightbox";
import { getGalleryImages } from "../../contentful";
const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    getGalleryImages().then((data) => setImages(data));
  }, []);

  const photoClasses = ["", "big", "tall", "wide"];

  return (
    <>
      <h4>The Gallery</h4>
      {images.length > 0 && (
        <SRLWrapper>
          <div className='grid-wrapper'>
            {images.map((p) => {
              const randomIdx = Math.floor(Math.random() * 3);

              return (
                <div className={`${photoClasses[randomIdx]} image-container`}>
                  {" "}
                  <img
                    src={p.fields.image.fields?.file.url}
                    alt={p.fields?.description}
                  />
                  {p.fields.description && (
                    <div className='image-container__description'>
                      <span>{p.fields.description}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </SRLWrapper>
      )}
    </>
  );
};

export default Gallery;
