import React, { useState, useEffect } from "react";
import { getEvents, getCalendarPage } from "../contentful";
import EventItem from "../components/EventItem";
import marked from "marked";
import { FaTimesCircle } from "react-icons/fa";
import EventsDisplay from "../components/EventsDisplay/EventsDisplay";
import Translator from "../components/i18n/Translator";

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [background, setBackground] = useState(null);

  let info;

  if (selectedEvent) {
    if (!localStorage.lang || localStorage.lang === "nl-NL") {
      info = marked(selectedEvent.infoNl);
    } else if (localStorage.lang === "en-US") {
      info = marked(selectedEvent.infoEn);
    } else if (localStorage.lang === "fr-FR") {
      info = marked(selectedEvent.infoFr);
    } else if (localStorage.lang === "de-DE") {
      info = marked(selectedEvent.infoDe);
    }
  }

  useEffect(() => {
    getEvents().then((data) => setEvents(data));
    getCalendarPage().then((data) =>
      setBackground(data[0].fields.backgroundImage.fields.file.url)
    );
  }, []);

  const today = new Date();
  const pastEvents = events
    .filter((e) => new Date(e.fields.date) < today)
    .reverse();

  return (
    <div
      className='page-background'
      style={
        background
          ? {
              backgroundImage: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${background})`,
            }
          : {}
      }
    >
      <div className='page-content '>
        {" "}
        <h3>
          {" "}
          <Translator path='NextConcerts' />
        </h3>
        <EventsDisplay
        calendarPage
          events={events.filter((e) => new Date(e.fields.date) > today)}
        />
        <hr />
        {pastEvents.length > 0 && (
          <h3>
            <Translator path='PastEvents' />
          </h3>
        )}
        <EventsDisplay events={pastEvents} />
      </div>
    </div>
  );
};

export default Calendar;
