// Lastly, we import everything and export a single object

import enUsTranslations from "./en-us";
import frFRTranslations from "./fr-fr";
import nlNLTranslations from "./nl-nl";
import deDETranslations from "./de-DE";

export default {
  "en-US": enUsTranslations,
  "nl-NL": nlNLTranslations,
  "fr-FR": frFRTranslations,
  "de-DE": deDETranslations,
};
