import React, { useState, useEffect } from "react";
import { getRecordings, getRecordingsPage } from "../contentful";
import RecordingsHero from "../components/RecordingsHero";
import Translator from "../components/i18n/Translator";

const Recordings = () => {
  const [recordings, setRecordings] = useState([]);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRecordings().then((data) => setRecordings(data));
    getRecordingsPage().then((data) =>
      setBackground(data[0].fields.backgroundImage.fields.file.url)
    );
  }, []);
  return (
    <div
      className='page-background'
      style={
        background
          ? {
              backgroundImage: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${background})`,
            }
          : {}
      }
    >
      <div className='page-content'>
        <RecordingsHero
          recordings={recordings}
          title={<Translator path='Solo' />}
          solo
        />
        <RecordingsHero
          recordings={recordings}
          title={<Translator path='EnsembleOrgan' />}
          organ
        />
        <RecordingsHero
          recordings={recordings}
          title={<Translator path='EnsembleHarpsichord' />}
          harpsichord
        />
      </div>
    </div>
  );
};

export default Recordings;
