import React, { useState } from "react";
import { getEvents } from "../../contentful";
import EventItem from "../EventItem";
import marked from "marked";
import { FaTimesCircle } from "react-icons/fa";

const EventsDisplay = ({ events, calendarPage }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  let info;

  if (selectedEvent) {
    if (!localStorage.lang || localStorage.lang === "nl-NL") {
      info = marked(selectedEvent.infoNl);
    } else if (localStorage.lang === "en-US") {
      info = marked(selectedEvent.infoEn);
    } else if (localStorage.lang === "fr-FR") {
      info = marked(selectedEvent.infoFr);
    } else if (localStorage.lang === "de-DE") {
      info = marked(selectedEvent.infoDe);
    }
  }

  return (
    <>
      {events.map((e, i) => (
        <div style={calendarPage ? { marginBottom: 130 } : { width: "100%" }}>
          <EventItem
            calendarPage
            key={i}
            event={e.fields}
            showMore={() => setSelectedEvent(e.fields)}
          />
        </div>
      ))}
      {selectedEvent && (
        <>
          <div className='event-details-modal'>
            <div>
              <FaTimesCircle
                className='event-details-modal--close'
                onClick={() => setSelectedEvent(null)}
              />
            </div>
            <h3>{selectedEvent.title}</h3>
            <section
              className='event-details-modal__text'
              dangerouslySetInnerHTML={{
                __html: info,
              }}
            />
          </div>
          <div className='backdrop' onClick={() => setSelectedEvent(null)} />
        </>
      )}
    </>
  );
};

export default EventsDisplay;
