import React, { useState, useEffect } from "react";
import { getPressReviews, getPressPage } from "../contentful";
import ReviewCard from "../components/ReviewCard";

const Press = () => {
  const [reviews, setReviews] = useState([]);
  const [background, setBackground] = useState(null);

  useEffect(() => {
    getPressReviews().then((data) => setReviews(data));
    getPressPage().then((data) =>
      setBackground(data[0].fields.backgroundImage.fields.file.url)
    );
  }, []);

  return (
    <div
      className='page-background'
      style={
        background
          ? {
              backgroundImage: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${background})`,
            }
          : {}
      }
    >
      <div className='page-content'>
        {reviews.map((r) => (
          <ReviewCard review={r} />
        ))}
      </div>
    </div>
  );
};

export default Press;
