export default {
  translations: {
    Biog: "Biografie",
    Recordings: "Opnames",
    Calendar: "Kalender",
    Projects: "Projecten",
    Press: "Pers",
    Teaching: "Lessen",
    Media: "Media",
    Contact: "Contact",
    More: "Meer",
    OrganistKlavecinist: "Orgel / Klavecimbel",
    Gallery: "Gallery",
    ReadMore: "meer",
    ReadLess: "minder",
    ViewAllDates: "Kalender >",
    ViewAllRecordings: "Opnames >",
    NewRelease: "Nieuw",
    PastEvents: "Voorbije Concerten",
    NextConcerts: "Komende Concerten",
    Solo: "Solo",
    EnsembleOrgan: "In ensembles: Orgel",
    EnsembleHarpsichord: "In ensembles: Klavecimbel",
  },
};
