export default {
  translations: {
    Biog: "Biographie",
    Recordings: "Enregistrements",
    Calendar: "Calendrier",
    Contact: "Contacter",
    More: "Plus",
    OrganistKlavecinist: "Orgue / Clavecin",
    Press: "Presse",
    Media: "Médias",
    Gallery: "Gallerie",
    Teaching: "Cours",
    Projects: "Projets",
    ReadMore: "plus",
    ReadLess: "moins",
    ViewAllDates: "Calendrier >",
    ViewAllRecordings: "Enregistrements >",
    PastEvents: "Concerts Passés",
    NextConcerts: "Concerts Suivants",
    NewRelease: "Nouveau",
    Solo: "Solo",
    EnsembleOrgan: "Ensemble: Orgue",
    EnsembleHarpsichord: "Ensemble: Clavecin",
  },
};
