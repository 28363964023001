import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTimesCircle,
} from "react-icons/fa";
import Translator from "../i18n/Translator";
import I18n from "../i18n/i18n";
import { NAV_ITEMS } from "../../config";

import "./SideDrawer.scss";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  const sideNavItems = [
    ...NAV_ITEMS,
    { label: "Media", path: "/media" },
    { label: "Gallery", path: "/gallery" },
  ];

  return (
    <div>
      <nav className={drawerClasses}>
        <div className='close-cross'>
          <FaTimesCircle id='cross' size={20} onClick={props.click} />
        </div>
        <div className='sidenav-items'>
          <NavLink
            onClick={props.click}
            activeClassName='active'
            className='sidenav-items__link'
            exact
            to='/'
          >
            <Translator path='Home' />
          </NavLink>
          {sideNavItems.map((item) => (
            <NavLink
              key={item.path}
              onClick={props.click}
              activeClassName='active'
              className='sidenav-items__link'
              to={item.path}
            >
              <Translator path={item.label} />
            </NavLink>
          ))}
          <span
            onClick={() => {
              props.openContact();
              props.click();
            }}
            style={{ cursor: "pointer" }}
            className='sidenav-items__link'
          >
            <Translator path='Contact' />
          </span>
          <div className='i18n'>
            <I18n drawerFooter />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;
