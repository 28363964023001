export default {
  translations: {
    Biog: "Biografie",
    Recordings: "Aufnahmen",
    Contact: "Kontakt",
    Calendar: "Kalender",
    More: "Mehr",
    OrganistKlavecinist: "Orgel / Cembalo",
    Press: "Pressemeldungen",
    Media: "Medien",
    Gallery: "Gallery",
    Projects: "Projekte",
    Teaching: "Kurse",
    ReadMore: "mehr",
    ReadLess: "weniger",
    ViewAllDates: "Kalender >",
    ViewAllRecordings: "Aufnahmen >",
    PastEvents: "Vergangene Konzerte",
    NextConcerts: "Kommende Konzerte",
    NewRelease: "Neu",
    Solo: "Solo",
    EnsembleOrgan: "In Ensembles: Orgel",
    EnsembleHarpsichord: "In Ensembles: Cembalo",
  },
};
