import React from "react";
import "./styles.scss";
import Translator from "../i18n/Translator";

const index = ({ event, showMore }) => {
  let info;
  if (event.infoEn || event.infoNl || event.infoFr || event.infoDe) {
    info = (
      <span className='event-item-container--more-link' onClick={showMore}>
        <Translator path='More' />
      </span>
    );
  }
  const date = new Date(event.date);
  return (
    <div className='event-item-container'>
      <div className='event-item-container__date'>
        <p>{date.toLocaleDateString()}</p>
        <p className='event-item-container__time'>
          {date.getHours()}:{String(date.getMinutes()).padStart(2, "0")}
        </p>
      </div>
      <div>
        <p className='event-item-container__title'>{event.title}</p>
        <p className='event-item-container__venue'>{event.venue}</p>
      </div>
      {info}
    </div>
  );
};

export default index;
