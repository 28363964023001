import React, { useState, useEffect } from "react";
import marked from "marked";
import { getTeachingPage } from "../contentful";

const Lessons = () => {
  const [content, setContent] = useState();

  useEffect(() => {
    getTeachingPage().then((data) => setContent(data[0].fields));
  }, []);

  let text;
  if (content) {
    if (!localStorage.lang || localStorage.lang === "nl-NL") {
      text = marked(content.textNl);
    } else if (localStorage.lang === "en-US") {
      text = marked(content.textEn);
    } else if (localStorage.lang === "fr-FR") {
      text = marked(content.textFr);
    } else if (localStorage.lang === "de-DE") {
      text = marked(content.textDe);
    }
  }
  return (
    <div>
      {content && (
        <div
          className='page-content page-background'
          style={
            content.backgroundImage
              ? {
                  backgroundImage: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(${content.backgroundImage.fields.file.url})`,
                }
              : {}
          }
        >
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <div className='lessons-page__images'>
            {content.gallery.map((image) => (
              <div className='lessons-page__images-image'>
                <img src={image.fields.file.url} />
                <br />
                <small>{image.fields.description}</small>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Lessons;
