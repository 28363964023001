import React, { useState, useEffect } from "react";
import { getYoutube } from "../contentful";
import marked from "marked";
import Vimeo from "../components/Vimeo/Vimeo";

const Media = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getYoutube().then((data) => setVideos(data));
  }, []);
  return (
    <div className='page-content'>
      <div className='videos-container'>
        {videos.map((v) => (
          <div className='video-card'>
            {v.fields.youtubeCode && <section dangerouslySetInnerHTML={{ __html: marked(v.fields.youtubeCode) }} />}
            {v.fields.vimeoCode && <Vimeo code={v.fields.vimeoCode} />}

            {v.fields.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: marked(v.fields.description),
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Media;
