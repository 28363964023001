export default {
  translations: {
    Biog: "Biography",
    Recordings: "Recordings",
    Contact: "Contact",
    More: "More",
    OrganistKlavecinist: "Organ / Harpsichord",
    Press: "Press",
    Teaching: "Lessons",
    Media: "Media",
    Gallery: "Gallery",
    Projects: "Projects",
    ReadMore: "more",
    ReadLess: "less",
    ViewAllDates: "All concerts >",
    ViewAllRecordings: "All recordings >",
    PastEvents: "Past Concerts",
    NextConcerts: "Next Concerts",
    NewRelease: "New",
    Solo: "Solo",
    EnsembleOrgan: "Ensemble: Organ",
    EnsembleHarpsichord: "Ensemble: Harpsichord",
  },
};
